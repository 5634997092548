<template>
  <div
    class="px-3 py-2 flex cursor-pointer"
    style="width: fit-content"
    :class="
      data.id === itemPriority
        ? ' border-1 border-solid border-ems-main2'
        : 'border-1 border-solid border-ems-gray600 '
    "
  >
    <img
      v-if="data.name"
      :src="require(`@/static/img/group-management/priority-${data.name}.png`)"
      :alt="data.icon"
      width="24"
      height="24"
      class="mr-[6px] h-[24px]"
    />
    <span class="text-base text-ems-gray300">{{ t(data.title) }}</span>
  </div>
</template>
<script setup>
import VueTypes from 'vue-types';
import { i18n } from '@/main';
const { t } = i18n.global;
defineProps({
  data: VueTypes.object.def({
    title: '',
    name: '',
    id: 0,
  }),
  itemPriority: VueTypes.number.def(0),
});
</script>
